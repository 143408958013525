
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import Chart from 'primevue/chart'
import formattersMixin from '@/mixins/formatters'
import Calendar from 'primevue/calendar'
import moment from 'moment'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
@Options({
  components: {
    Column,
    DataTable,
    TabView,
    TabPanel,
    CardBase,
    Chart,
    Calendar
  },
  mixins: [formattersMixin]
})
export default class ProviderDetails extends Vue {
  rangeForLineChart = ['', '']
  horizontalData = {
    labels: ['BTC-CAD', 'BTC-USD', 'ETH-USD', 'ETH-CAD', 'BCH-USD'],
    datasets: [
      {
        label: 'REVENUES',
        backgroundColor: '#FAB710',
        data: [28, 48, 40, 19, 86, 27, 40]
      }
    ]
  }
  horizontalOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  }
  lineData = {
    labels: [
      '05-11-21',
      '06-11-21',
      '07-11-21',
      '08-11-21',
      '09-11-21',
      '10-11-21',
      '11-11-21'
    ],
    datasets: [
      {
        label: 'Total',
        data: [28, 48, 40, 19, 86, 27, 90, 500],
        fill: false,
        borderColor: '#FFA726',
        tension: 0.4
      }
    ]
  }

  mounted() {
    this.initLineChart()
  }

  initLineChart() {
    this.rangeForLineChart[1] = moment(new Date()).format('YYYY-MM-DD')
    this.rangeForLineChart[0] = moment(new Date())
      .subtract(7, 'days')
      .format('YYYY-MM-DD')
    this.changeRangeDate()
  }

  changeRangeDate() {
    const start = moment(this.rangeForLineChart[0], 'YYYY-MM-DD')
    const end = moment(this.rangeForLineChart[1], 'YYYY-MM-DD')
    const diffDays = Math.abs(moment.duration(start.diff(end)).asDays())
    if (diffDays <= 0) return
    this.lineData.labels = []
    this.lineData.labels.push(start.format('DD-MM'))
    for (let i = 1; i < diffDays; i++) {
      this.lineData.labels.push(start.add(1, 'days').format('DD-MM'))
    }
    this.lineData.labels.push(end.format('DD-MM'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(this.$refs['line-chart'] as any).refresh()
  }
}
